<template>
  <div>
    <Form ref="loginForm" :model="form" :rules="rules">
      <FormItem prop="mobile">
          <i-input v-model="form.mobile" size="large" class="borerB" clearable :placeholder="$t('login_mobild_user')" @on-keyup.enter="handleSubmit()">

          </i-input>

      </FormItem>
      <FormItem prop="captchaCode">
        <Row align="middle">
          <Col span="16">
            <i-input type="text" :maxlength="4" size="large" class="borerB" clearable v-model="form.captchaCode" :placeholder="$t('login_captcha')" @on-keyup.enter="handleSubmit()">

            </i-input>
          </Col>
          <Col span="7" push="1">
            <div style="text-align: center;height: 36px; overflow: hidden;" @click="getCaptcha"><img :src="captchaUrl" height="100%" /></div>
          </Col>
        </Row>

      </FormItem>

      <FormItem prop="mobileCode">
        <Row>
          <Col span="16">
            <i-input type="text" :maxlength="4" size="large" class="borerB" clearable v-model="form.mobileCode" :placeholder="$t('login_mobild_code_tip')" @on-keyup.enter="handleSubmit()">

            </i-input>
          </Col>
          <Col span="7" push="1">
            <div class="get-code" @click="getMobileCode" v-if="mobileCodeStatus">{{ $t('login_get_code') }}</div>
            <div class="get-code-not" v-else>{{sec}}{{$t('login_mobild_code_unit')}}{{ $t('login_mobild_code_again') }}</div>
          </Col>
        </Row>

      </FormItem>
      <FormItem>
        <Button @click="handleSubmit" type="primary" size="large" :loading="loading" long>{{isWxLogin || isTeacherWxLogin ? $t('login_wxlogin') : $t('login_login')}}</Button>
      </FormItem>
<!--      <Row type="flex" justify="end" v-if="!isWxLogin && !isTeacherWxLogin">-->
<!--        <Col>-->
<!--          {{$t('login_account_no')}}<span style="color: #2d8cf0;cursor: pointer" @click="toRegisterPage">{{$t('login_register')}}</span>-->
<!--        </Col>-->
<!--      </Row>-->
    </Form>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import util from'@/utils/tools.js';
import {tokenName} from '@/router/init.js'

export default {
  data () {
    const validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('login_fill_account')));
      }
      localStorage.setItem('mobile',value);
      callback();
    };
    const validateCaptchaCode = (rule, value, callback) => {
      if (!value ) {
        return callback(new Error(this.$t('login_captcha_tip')));
      }else{
        if(!util.captchaCodeCheck(value,this.captchaHash)){
          return callback(new Error(this.$t('login_captcha_tip')));
        }else{
          callback();
        }
      }

    };
    const validateMobileCode = (rule, value, callback) => {
      if (!value ) {
        return callback(new Error(this.$t('login_mobild_code_tip')));
      }else {
        if (!util.captchaCodeCheck(value, this.mobileHash)) {
          return callback(new Error(this.$t('login_captcha_tip')));
        } else {
          callback();
        }
      }
      // callback();
    };
    return {
      loading: false,
      form: {
        mobile: localStorage.getItem('mobile') || '',
        captchaCode: '',
        mobileCode:'',
        app_key: '',
      },
      lang: Cookies.get('lang') || 'zh-CN',
      access_token: '',
      rules: {
        mobile: [
          { validator: validateName, trigger: 'blur' }
        ],
        captchaCode: [
          { validator: validateCaptchaCode, trigger: 'blur' }
        ],
        mobileCode:[
          { validator: validateMobileCode, trigger: 'blur' }
        ]
      },
      captchaUrl:'',
      captchaKey:'',
      captchaHash:'',
      mobileCodeStatus:true,
      sec:60,
      mobileHash:'',
    };
  },
  props:{
    isWxLogin:{
      default:false,
      type:Boolean,
    },
    wxOpenId:{
      default:'',
      type:String,
    },
    unionid:{
      default:'',
      type:String,
    },
    isTeacherWxLogin:{
      default:false,
      type:Boolean,
    },
    appid:{
      default:'',
      type:String,
    }
  },
  created(){
    this.getCaptcha();
  },
  mounted () {
    // this.form.username = Cookies.get('liteLoginAccount') || '';
  },
  methods: {
    toRegisterPage(){
      this.$router.push({
        name: 'register',
        query:{
          from:this.$route.query.from
        }
      });
    },
    //获取图片验证码
    getCaptcha(){
      let params = {
        regenerate:1
      };
      this.api.index.getCaptcha(params).then((res)=>{
        this.captchaUrl = res.bin;
        this.captchaKey = res.key;
        this.captchaHash = res.hash;
      })
    },
    getMobileCode(){
      if(!this.form.mobile){
        this.$Message.warning(this.$t('login_mobild_user'));
        return;
      }
      if(!this.form.captchaCode){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      if(!util.captchaCodeCheck(this.form.captchaCode,this.captchaHash)){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      let data = {
        op:'sendMobile',
        mobile: this.form.mobile,
        verifyCode: this.form.captchaCode,
        verifyCodeKey:this.captchaKey,
      };
      this.api.index.login(data).then((res)=>{
        this.mobileCodeStatus = false;
        this.mobileHash = res.hash;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)

      })
    },
    handleSubmit () {
      if (this.loading) {
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            mobile: this.form.mobile,
            verifyCode: this.form.captchaCode,
            verifyCodeKey: this.captchaKey,
            mobileCode:this.form.mobileCode,
          };
          if(this.isWxLogin){
            data.appid = this.constant.APPID;
            data.openid = this.wxOpenId;
            data.unionid = this.unionid;
          }
          if(this.isTeacherWxLogin){
            data.appid = this.appid;
            data.openid = this.wxOpenId;
            data.unionid = this.unionid;
          }
          console.log(this.api.index.login,'ddddd');
          this.api.index.login(data).then((res)=>{
            console.log(res,'dddd')
            this.loading = false;

            this.$store.commit('login', {
              accessToken: res.access_token,
              userName: this.form.mobile
            });
            this.access_token = res.access_token;

            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            let domain = util.getDomain();
            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            //添加ai问答token

            Cookies.set(tokenName, res.access_token,{domain});
            util.setCookie('liteLoginAccount', this.form.mobile, 30);

            this.$store.commit('setLoginStatus', true);

            this.$emit('success');
            // this.$router.push('/');
            // this.getUserInfo();
          }).catch((err)=>{
            this.loading = false;
          });
          // $.ajax({
          //   url: api.index.login,
          //   method: 'POST',
          //   data: data,
          //   success: (res) => {
          //     this.loading = false;
          //     if (res.error) {
          //       this.$Message.destroy();
          //       this.$Message.warning({
          //         content: res.message,
          //         duration: 3
          //       });
          //     } else {
          //       this.$store.commit('login', {
          //         accessToken: res.data.access_token,
          //         userName: this.form.username
          //       });
          //       this.access_token = res.data.access_token;
          //
          //       //是否登录的标志
          //       Cookies.set('liteAccessToken', res.data.access_token);
          //       util.setCookie('liteLoginAccount', this.form.username, 30);
          //
          //       this.$store.commit('setLoginStatus', true);
          //       this.getUserInfo();
          //     }
          //   },
          //   error: (res, textStatus, responseText) => {
          //     util.handleAjaxError(this, res, textStatus, responseText, () => {
          //       this.loading = false;
          //     });
          //   }
          // });
        }
      });
    },
    getUserInfo () {
      // 获取用户信息
      const userInfo = this.$store.state.user.userInfo;

      console.log('login.vue.getUserInfo.userInfo: ')
      console.log(userInfo)

      // let userInfoRequest =
      // $.ajax({
      //       url: api.user.detail,
      //       method: 'POST',
      //       data: {
      //         access_token: userInfo.accessToken
      //       },
      //       success: (res) => {
      //         console.log('res: ')
      //         console.log(res)
      //
      //         if (res.error) {
      //           this.$Message.destroy();
      //           this.$Message.warning({
      //             content: res.message,
      //             duration: 3
      //           });
      //         } else {
      //           const userDetail = res.data.user;
      //           Cookies.set('liteMenuShrink', false);
      //           this.$store.state.app.settings = {
      //             ...res.data.settings
      //           };
      //           if (userDetail) {
      //             this.$store.state.user.userInfo = {
      //               ...this.$store.state.user.userInfo,
      //               ...userDetail
      //             };
      //             this.$store.commit('login', this.$store.state.user.userInfo);
      //
      //             console.log('this.$store.state.user: ')
      //             console.log(this.$store.state.user)
      //
      //             let lang = userDetail.language === '0' ? 'zh-CN' : 'en-US';
      //             // this.$store.commit('switchLang', lang);
      //             // 这里的直接修改store信息
      //             // 因为用上一行的方式切换语音是个异步操作 会导致下边的提示信息用的语言和用户看到的不一样
      //             localStorage.lang = lang;
      //             this.$store.state.app.lang = lang;
      //             this.$i18n.locale = lang;
      //             this.$store.commit('setPermissions', userDetail.permisstions);
      //             this.$store.commit('updateMenulist');
      //           }
      //           this.$Message.destroy();
      //           this.$Message.success(this.$t('login_success_tips'));
      //           this.$store.commit('clearAllTags');
      //           this.$router.push({
      //             path: '/home'
      //           });
      //         }
      //       }
      //     }
      // );

      this.$store.commit('requestUserInfo', userInfoRequest);
    },
  }
};
</script>

<style lang="scss" scoped>
.login-form-con{
  //box-shadow: 0px 10px 30px rgba(123,211,243,0.23);
  //padding: 25px 40px 60px;
  //background: #fff;
  //border-radius: 8px;

  .get-code{
    width: 100%;
    border: 1px solid #3399ff;
    color:#3399ff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  .get-code-not{
    width: 100%;
    border: 1px solid #c3cbd6;
    color:#c3cbd6;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
}
</style>
