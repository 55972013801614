<style lang="scss">
@import './login.scss';
</style>

<template>
  <div style="height: 100vh">
    <Spin fix v-if="loading"></Spin>
    <div  class="login" v-else :style="{backgroundImage:(info.background_img ? 'url(' + info.background_img + ')' : 'url('+backgroundImage+')')}">

      <div class="login-mask">
        <div class="home" v-if="!loading && info.id && !showLogin">
          <div class="home-top">
            <img v-if="info.full_logo" :src="info.full_logo" height="42" />
            <!--          <img v-else src="../../assets/images/new_icon/logo_w_icon.png" style="width: 158px" alt="">-->
            <p><span v-if="info.full_logo">·</span> {{ info.full_name || defaultInfo.name }}</p>
          </div>
          <div class="desc">{{info.intro}}</div>
          <div class="btns">
            <div class="btns-item" v-for="(item,index) in btnList" :key="index" @click="goLogin">{{ item.name }}</div>
          </div>
        </div>
        <div v-if="!loading && showLogin">
          <div class="login-logo">
            <img v-if="info.full_logo" :src="info.full_logo" height="42" alt="">
            <!--          <img v-else src="../../assets/images/new_icon/logo_w_icon.png" style="width: 158px" alt="">-->
            <p><span v-if="info.full_logo">·</span> {{ info.full_name || defaultInfo.name }}</p>
          </div>
          <div v-if="!perfectInfo">
            <div class="login-con">
              <Row class="login-form-con">
                <Col span="9" v-if="info.login_show_qrcode != '1'">
                  <div class="login-form-con-cont login-form-con-l">
                    <p class="wx-tit">扫码登录/注册</p>
                    <p class="wx-sub-tit">未注册用户扫码自动注册</p>
                    <TeacherWxLogin v-if="!perfectInfo" @wxSuccess="wxSuc" @success="loginSuc"></TeacherWxLogin>
                    <p class="wx-tip">打开微信App，扫一扫登录</p>
                  </div>

                </Col>
                <Col :span="info.login_show_qrcode != '1' ? 15 : 24">
                  <img class="close-login" v-if="info.id" src="../../assets/images/new_icon/guanbi.png" width="15" height="15" @click="goHome"/>
                  <div  class="login-form-con-cont">
                    <div class="login-tab">
                      <p :class="curTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{item.name}}</p>
                    </div>
                    <AccountLogin v-if="curTabId == 1" @success="loginSuc"></AccountLogin>
                    <PhoneLogin v-if="curTabId == 2" @success="loginSuc"></PhoneLogin>
                  </div>

                </Col>
              </Row>
            </div>
            <div class="privacy-agreement">
              登录视为您已同意<span @click="goAgree">《用户协议及隐私协议》</span>
            </div>
            <!--      <div class="register-site-ad" @click="goRegisterSite">-->
            <!--        <img src="../../assets/images/new_icon/register_ad.png" width="750" />-->
            <!--      </div>-->
          </div>
          <div class="perfect-info" v-else>
            <div class="top" >
              <div class="back">
                <span>重新扫码</span><Button @click="back">&lt; 返回</Button>
              </div>
              <div class="info">
                已授权登录成功，请先完善信息!
              </div>
            </div>
            <TeacherWxForm :appid="appid" :wxOpenId="wxOpenId" :unionid="unionid" :isCompany="false" @success="loginSuc"></TeacherWxForm>
          </div>
        </div>
        <div class="login-bot">推荐使用谷歌chrome浏览器访问</div>
      </div>

    </div>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
import util from'@/utils/tools.js';
import AccountLogin from "@/views/guest/components/accountLogin";
import PhoneLogin from "@/views/guest/components/phoneLogin";
import TeacherWxLogin from '@/views/guest/components/teacherWxLogin';
import TeacherWxForm from "@/views/guest/components/teacherWxForm";

export default {
  name:'login',
  data(){
    return {
      lang: Cookies.get('lang') || 'zh-CN',
      tabList:[
        {
          name:'账号登录',
          id:1
        },
        {
          name:'手机号登录',
          id:2
        },
      ],
      curTabId:1,
      appid:'',
      wxOpenId:'',
      unionid:'',
      perfectInfo:false,
      info:{},
      btnList:[],
      loading:true,
      showLogin:false,
      backgroundImage:require('../../assets/images/new_icon/bj.png'),
      defaultInfo:{
        name:'数字实战评测平台'
      }
    }
  },
  components:{
    AccountLogin,
    PhoneLogin,
    TeacherWxLogin,
    TeacherWxForm
  },
  mounted(){
    if(this.$route.query.code){
      this.curTabId = 3;
    }
    this.getInfo();
    // if(this.$route.query.from == 'website'){
    //   this.curTabId = 5;
    // }
  },
  methods:{
    getInfo(){
      this.loading = true;
      this.api.index.indexInit().then((res)=>{
        this.loading = false;
        if(res.site){
          this.info = res.site;
          console.log(this.info,'this.infothis.infothis.info')
          if(this.info.type == 0){
            this.btnList = [
              {
                name:'学生登录'
              },
              {
                name:'教师登录'
              },
              {
                name:'管理员登录'
              },
            ];
          }else{
            this.btnList = [
              {
                name: this.info.language == '0' ? '登录' : 'Login'
              }
            ];
          }
        }else{
          // this.showLogin = true;
          this.goLogin();
        }

      }).catch((err)=>{
        this.loading = false;
      })
    },
    goLogin(){
      let token = Cookies.get(this.constant.tokenName);
      if(token){
        this.loginSuc();
      }else{
        this.showLogin = true;
      }
    },
    // goLogin(){
    //   this.showLogin = true;
    // },
    goHome(){
      this.showLogin = false;
    },
    goAgree(){
      window.open('https://alidocs.dingtalk.com/i/p/dN0G7R9PrNRrMmWY/docs/R1zknDm0WRYaKBwyfr59vjO28BQEx5rG','_blank');
    },
    changeLang (lang) {

      this.lang = lang;
      this.$store.commit('switchLang', lang);

      localStorage.lang = lang;
      this.$store.state.app.lang = lang;
      this.$i18n.locale = lang;

      document.title = this.$t(this.$route.meta.title);
    },
    changeTab(data){
      this.curTabId = data.id;
    },
    loginSuc(){
      // console.log(this.$route,'this.$routethis.$route')
      this.$store.commit('loginSuccessRouter',{form:this.$route.query.from,vm:this,fullPath:this.$route.query.from})
    },
    wxSuc(data){  //微信扫码成功 完善信息
      this.perfectInfo = true;
      this.appid = data.appid;
      this.wxOpenId = data.wxOpenId;
      this.unionid = data.unionid;
    },
    goRegisterSite(){
      this.$router.push({
        path:'/registersite',
        query:{
          from:this.$route.query.from
        }
      })
    },
    back(){
      this.perfectInfo = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .login{
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center center;
    min-height: 600px;
    >div{
      position: relative;
      .login-bot{
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        color:#999999;
      }
    }
  }
</style>
