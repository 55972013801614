<template>
  <div>
    <Form ref="loginForm" :model="form" :rules="rules">
      <FormItem prop="mobile">
        <i-input v-model="form.mobile" size="large" class="borerB" clearable placeholder="请输入账号或手机号" @on-keyup.enter="handleSubmit()">
        </i-input>
      </FormItem>
      <FormItem prop="password">
        <i-input type="password" :maxlength="32" size="large" class="borerB" clearable v-model="form.password" :placeholder="$t('login_enter_password')" @on-keyup.enter="handleSubmit()">
        </i-input>
        <input type="password" hidden autocomplete="new-password" value="" />
      </FormItem>
      <FormItem prop="verifyCode">
        <Row align="middle">
          <Col span="18">
            <i-input type="text" :maxlength="4" size="large" class="borerB" clearable v-model="form.verifyCode" :placeholder="$t('login_captcha')" @on-keyup.enter="handleSubmit()">
            </i-input>
          </Col>
          <Col span="5" push="1">
            <div style="text-align: center;height: 36px; overflow: hidden;" @click="getCaptcha"><img :src="captchaUrl" height="100%" /></div>
          </Col>
        </Row>

      </FormItem>
      <FormItem>
        <Button @click="handleSubmit" type="primary" size="large" :loading="loading" long>{{(isWxLogin || isTeacherWxLogin)  ? $t('login_wxlogin') : $t('login_login')}}</Button>
      </FormItem>
      <Row type="flex" justify="space-between">
        <Col>
          <span style="color: #2d8cf0;cursor: pointer" @click="toForgetPasswordPage">{{$t('login_forget_password')}}</span>
        </Col>
<!--        <Col>-->
<!--          {{$t('login_account_no')}}<span style="color: #2d8cf0;cursor: pointer" @click="toRegisterPage">{{$t('login_register')}}</span>-->
<!--        </Col>-->
      </Row>
    </Form>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import util from '../../../utils/tools.js';
import {tokenName} from '@/router/init.js'

export default {
  data () {
    const validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('login_fill_account')));
      }
      localStorage.setItem('mobile',value);
      callback();
    };
    const validatePassword = (rule, value, callback) => {
      if (!value || value.length < 6) {
        return callback(new Error(this.$t('login_validate_password')));
      }
      callback();
    };
    const validateVerifyCode = (rule, value, callback) => {
      if (!value ) {
        return callback(new Error(this.$t('login_captcha_tip')));
      }else{
        if(!util.captchaCodeCheck(value,this.captchaHash)){
          return callback(new Error(this.$t('login_captcha_tip')));
        }else{
          callback();
        }
      }

    };
    return {
      loading: false,
      form: {
        mobile: localStorage.getItem('mobile') || '',
        password: '',
        verifyCode:''
      },
      lang: Cookies.get('lang') || 'zh-CN',
      access_token: '',
      rules: {
        mobile: [
          { validator: validateName, trigger: 'blur' }
        ],
        password: [
          { validator: validatePassword, trigger: 'blur' }
        ],
        verifyCode:[
          { validator: validateVerifyCode, trigger: 'blur' }
        ]
      },
      captchaUrl:'',
      captchaKey:'',
      captchaHash:'',
    };
  },
  props:{
    isWxLogin:{
      default:false,
      type:Boolean,
    },
    wxOpenId:{
      default:'',
      type:String,
    },
    unionid:{
      default:'',
      type:String,
    },
    isTeacherWxLogin:{
      default:false,
      type:Boolean,
    },
    appid:{
      default:'',
      type:String,
    }
  },
  created(){
    this.getCaptcha();
  },
  mounted () {
    this.form.password = '';
  },
  methods: {
    toRegisterPage(){
      this.$router.push({
        name: 'register',
        query:{
          from:this.$route.query.from
        }
      });
    },
    toForgetPasswordPage () {
      this.$router.push({
        name: 'forget-password',
        query:{
          from:this.$route.query.from
        }
      });
    },
    //获取图片验证码
    getCaptcha(){
      let params = {
        regenerate:1
      };
      this.api.index.getCaptcha(params).then((res)=>{
        this.captchaUrl = res.bin;
        this.captchaKey = res.key;
        this.captchaHash = res.hash;
      })
    },
    handleSubmit () {
      console.log("denglu");
      if (this.loading) {
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            mobile: this.form.mobile,
            password: util.pswEncryption(this.form.password),
            verifyCode: this.form.verifyCode,
            verifyCodeKey: this.captchaKey,
          };
          if(this.isWxLogin){
            data.appid = this.constant.APPID;
            data.openid = this.wxOpenId;
            data.unionid = this.unionid;
          }
          if(this.isTeacherWxLogin){
            data.appid = this.appid;
            data.openid = this.wxOpenId;
            data.unionid = this.unionid;
          }
          console.log(this.api.index.login,'ddddd');
          this.api.index.login(data).then((res)=>{
            this.loading = false;
            this.$store.commit('login', {
              accessToken: res.access_token,
              userName: this.form.mobile
            });
            this.access_token = res.access_token;
            let domain = util.getDomain();
            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            //添加ai问答token

            Cookies.set(tokenName, res.access_token,{domain});

            util.setCookie('liteLoginAccount', this.form.mobile, 30);

            this.$store.commit('setLoginStatus', true);
            this.$emit('success');
          }).catch((err)=>{
            this.loading = false;
          });
        }
      });
    },
  }
};
</script>

<style lang="scss">
//.login-form-con{
//  box-shadow: 0px 10px 30px rgba(123,211,243,0.23);
//  padding: 25px 40px 60px;
//  background: #fff;
//  border-radius: 8px;
//}
</style>
